<template>
  <div class="chat">
      <el-row type="flex" justify="center">
      <el-col :span="16">
          <el-container>
              <el-aside width="240px">
                  <el-card>
                      <div>
                          <span>客服</span>
                          <div class="bottom clearfix">
                              <time class="time">2018.5.21</time>
                              <el-button type="text" class="button">操作按钮</el-button>
                          </div>
                      </div>
                  </el-card>
                  <el-menu :default-active="String(currentSessionId)">
                      <el-menu-item v-for="session in sessions" @click="currentSessionId=session.id;currentSession.user.badge=0;" :key="session.id" :index="String(session.id)">{{ session.user.name }}<el-badge v-if="session.user.badge > 0" class="mark" :value="session.user.badge" /></el-menu-item>
                  </el-menu>
              </el-aside>

              <el-main id="chat">
                  <div class="message">
                      <ul v-if="currentSessionId">
                          <li v-for="item in currentSession.messages">
                              <p class="time">
                                  <span>{{ item.time | time }}</span>
                              </p>
                              <div class="main" :class="{ self: item.type == 'service' }">
<!--                                  <img class="avatar" :src="item.type == 'service' ? user.avatar : currentSession.user.avatar" />-->
                                  <img class="avatar" src="../../assets/clientchat.jpg" />
                                <div class="text">{{ item.message }}</div>
                              </div>
                          </li>
                      </ul>
                  </div>
                  <div class="word">
                      <el-input type="textarea" :rows="4" @keyup="onKeyup" v-model="content" :disabled="!currentSessionId">
                      </el-input>
                  </div>
                  <div class="send">
                      <el-button plain size="mini" v-on:click="sendMessage">发送</el-button>
                  </div>
              </el-main>
          </el-container>
      </el-col>
  </el-row>
  </div>

</template>
<script>
import { domain } from '@/api/url'
export default {
  name: 'messagNotice',
  data() {
    return {
      content: '',
      user: {
        name: 'coffce',
        avatar: '/static/images/self.jpg'
      },
      sessions: [],
      currentSessionId: null,
      socket: null
    }
  },
  computed: {
    currentSession: function () {
      let currentSessionId = this.currentSessionId;
      return this.sessions.find(session => session.id === currentSessionId);
    }
  },
  methods: {
    onKeyup: function () {
      return true;
    },
    sendMessage(event) {
      let message = this.content;

      if (message) {
        let id = this.currentSession.id;
        let type = 'service';
        let time = new Date();
        let name = this.user.name;
        let avatar = this.user.avatar;

        this.currentSession.messages.push({
          time: time.toString(),
          type: type,
          message: message
        });

        this.socket.send(JSON.stringify({
          'id': id,
          'type': 'service_message',
          'time': time.toString(),
          'name': name,
          'avatar': avatar,
          'message': message
        }));
        if (this.currentSessionId) {
          this.$nextTick(function () {
            $('div.message').scrollTop($('div.message ul')[0].scrollHeight);
          })
        }
        this.content = '';
      }
    }
  },
  filters: {
    // 将日期过滤为 hour:minutes
    time(date) {
      if (typeof date === 'string') {
        date = new Date(date);
        return date.toLocaleTimeString();
      }
      return date;
    }
  },
  mounted() {
    //和服务器建立WebSocket链接
    let ws_protocol = '';
    let connection_string = '';
    let self = this;
    if (window.location.protocol === 'https:') {
      ws_protocol = 'wss://';
    } else {
      ws_protocol = 'ws://';
    }
    connection_string = ws_protocol + domain + '/ws/service/1/';
    console.log('in service.html, connection string:' + connection_string);
    this.socket = new WebSocket(connection_string);

    //监听服务器转发的客户消息
    this.socket.onmessage = function (e) {
      let data = JSON.parse(e.data);
      let id = data['id'];
      let time = data['time'];
      let type = data['type']
      let message = data['message'];
      let name = data['name'];
      let avatar = data['avatar'];
      switch (type) {
        case 'client_message':
          let session = self.sessions.find(session => session.id === id);
          if (session) {
            //找到了已经有的用户
            session.messages.push({
              time: time,
              type: 'client',
              message: message,
            });
            if (session.id != self.currentSessionId) {
              session.user.badge += 1;
            }
          } else {
            //没有找到用户，需要新建一个session
            self.sessions.push({
              id: id,
              user: {
                name: name,
                avatar: avatar,
                badge: 1,
              },
              messages: [{
                time: time,
                type: 'client',
                message: message,
              }]
            })
          }
          if (self.currentSessionId) {
            self.$nextTick(function () {
              $('div.message').scrollTop($('div.message ul')[0].scrollHeight);
            })
          }
          break;
        case 'service_message':
          console.log('service_message');
          break;
      }
    };

    this.socket.onopen = function () {
      console.log("opening a connection...");
    };

    this.socket.onerror = function (evt) {
      console.log("ERR: " + evt.data);
    };

    this.socket.onclose = function (e) {
      console.error('Chat socket closed unexpectedly');
    };

  }
}
</script>

<style lang="scss">

    .el-aside {
        color: #333;
        height: 600px;
        background-color: #fff;
    }

    .el-card {
        padding: 14px;
    }

    .el-card .time {
        font-size: 13px;
        color: #999;
    }

    .el-card .bottom {
        margin-top: 13px;
        line-height: 12px;
    }

    .el-card .button {
        padding: 0;
        float: right;
    }

    .el-card .image {
        width: 100%;
        display: block;
    }

    .el-card .clearfix:before,
    .el-card .clearfix:after {
        display: table;
        content: "";
    }

    .el-card .clearfix:after {
        clear: both
    }

    .el-main {
        padding: 0px;
        border-left: solid 1px #ddd;
    }

    #chat {
        margin: 0px auto;
        overflow: hidden;
        border-radius: 3px;
        box-sizing: border-box;
        color: #4d4d4d;
        font: 14px/1.4em 'Helvetica Neue', Helvetica, 'Microsoft Yahei', Arial, sans-serif;
        background-size: cover;
        background-color: #fff;
    }

    #chat *:before *:after {
        box-sizing: inherit;
    }

    #chat .message {
        height: 420px;
        padding: 10px 15px;
        overflow-y: scroll;
        border: 1px solid #eee;
        background-color: #f5f5f5
    }

    #chat .message ul {
        margin: 0;
        padding: 0;
        list-style: none;
    }

    #chat .message li {
        margin-bottom: 15px;
    }

    #chat .message .time {
        margin: 7px 0;
        text-align: center;
    }

    #chat .message .time>span {
        display: inline-block;
        padding: 0 18px;
        font-size: 12px;
        color: #fff;
        border-radius: 2px;
        background-color: #dcdcdc;
    }

    #chat .message .avatar {
        float: left;
        margin: 0 10px 0 0;
        border-radius: 3px;
        height: 30px;
        width: 30px;
    }

    #chat .message .text {
        display: inline-block;
        position: relative;
        padding: 0 10px;
        max-width: calc(100% - 80px);
        min-height: 30px;
        line-height: 2.5;
        font-size: 12px;
        text-align: left;
        word-break: break-all;
        background-color: #fafafa;
        border-radius: 4px;
    }

    #chat .message .text:before {
        content: " ";
        position: absolute;
        top: 9px;
        right: 100%;
        border: 6px solid transparent;
        border-right-color: #fafafa;
    }

    #chat .message .self {
        text-align: right;
    }

    #chat .message .self .avatar {
        float: right;
        margin: 0 0 0 10px;
    }

    #chat .message .self .text {
        background-color: #b2e281;
    }

    #chat .message .self .text:before {
        right: inherit;
        left: 100%;
        border-right-color: transparent;
        border-left-color: #b2e281;
    }

    #chat .word {
        height: 90px;
        border-top: solid 1px #ddd;
        padding: 10px 10px 0 10px;
    }

    #chat .word textarea {
        padding: 0;
        height: 100%;
        width: 100%;
        border: none;
        outline: none;
        font-family: "Micrsofot Yahei";
        resize: none;
    }

    #chat .send {
        text-align: right;
        padding: 5px;
        height: 40px;
        border: none;
        outline: none;
        font-family: "Micrsofot Yahei";
        color: #888;
    }
</style>
