<template>
    <div class="lycontainer" >
        <ly-xterm :wsuri="wsuri">12121212</ly-xterm>
    </div>
</template>

<script>
    import LyXterm from "@/components/terminal/xterm";
    import {domain,url} from '@/api/url'
    export default {
        name: "lyterminal",
        components: {LyXterm},
        data(){
            // return{
            //     wsuri:(window.location.protocol === 'http:' ? 'ws://' : 'wss://') + domain+"/ws/webssh/",
            // }
        },
        created() {
          console.log('1111111111')
            let url = window.location.href
            let getqyinfo = url.split('?')[1]
            let getqys = new URLSearchParams('?'+getqyinfo)
            let id = getqys.get('id')
            let host = getqys.get('host')
            document.title =host+"在线终端WEBSSH"
            console.log(this.wsuri, '67888888')
            this.wsuri = this.wsuri +"?id="+id
        },
    }
</script>
<style lang="scss" scoped>
    .lycontainer{
        background: black;
        height: 100% ;
        width: 100%;
        /*background: black;*/
        /*padding: 10px;*/
        overflow: hidden;
    }
</style>