<template>
    <div :class="{'ly-is-full':isFull}">
        <div class="tableSelect" ref="tableSelect">
            <el-form :inline="true" :model="formInline" label-position="left">
                <el-form-item label="订单编号：">
                    <el-input v-model.trim="formInline.order_id" maxlength="60"  clearable placeholder="订单编号" @change="search" style="width:200px"></el-input>
                </el-form-item>
<!--                <el-form-item label="购买人：">-->
<!--                    <el-input v-model.trim="formInline.buyer" maxlength="60"  clearable placeholder="购买人" @change="search" style="width:150px"></el-input>-->
<!--                </el-form-item>-->
<!--                <el-form-item label="下单时间：">-->
<!--                    <el-date-picker-->
<!--                            style="width:350px"-->
<!--                            v-model="timers"-->
<!--                            type="datetimerange"-->
<!--                            @change="timeChange"-->
<!--                            range-separator="至"-->
<!--                            start-placeholder="开始日期"-->
<!--                            end-placeholder="结束日期">-->
<!--                    </el-date-picker>-->
<!--                </el-form-item>-->
                <el-form-item label=""><el-button  @click="search" type="primary" icon="Search" v-show="isShowBtn('mallOrderManage','商城订单','Search')">查询</el-button></el-form-item>
                <el-form-item label=""><el-button  @click="handleEdit('','reset')" icon="Refresh">重置</el-button></el-form-item>
            </el-form>
        </div>
        <el-table :height="tableHeight" border :data="tableData" ref="tableref" v-loading="loadingPage" style="width: 100%" @selection-change="handleSelectionChange">
            <el-table-column type="selection" width="55"></el-table-column>
            <el-table-column type="index" width="60" align="center" label="序号">
                <template #default="scope">
                    <span v-text="getIndex(scope.$index)"></span>
                </template>
            </el-table-column>
            <el-table-column min-width="160" prop="order" label="订单编号"></el-table-column>
<!--            <el-table-column min-width="70" prop="avatar" label="用户头像">-->
<!--                    <template #default="scope">-->
<!--                        <img  :src="scope.row.userinfo.avatar ? scope.row.userinfo.avatar : defaultImg" style="width: 30px;height: 30px" :onerror="defaultImg">-->
<!--                    </template>-->
<!--            </el-table-column>-->
            <el-table-column min-width="120"  label="购买人">
                <template #default="scope">
                    <div>{{scope.row.purchaser}}</div>
                </template>
            </el-table-column>
            <el-table-column min-width="120"  label="商品评价">
                <template #default="scope">
                  <el-rate v-model="scope.row.score" disabled show-score color="#ff0000">
                </el-rate>
                </template>
            </el-table-column>
<!--            <el-table-column min-width="120"  label="商品评价">-->
<!--                <template #default="scope">-->
<!--                    <div>{{scope.row.comment}}</div>-->
<!--                </template>-->
<!--            </el-table-column>-->
<!--            <el-table-column min-width="120"  label="商家回复">-->
<!--                <template #default="scope">-->
<!--                    <div>{{scope.row.comment}}</div>-->
<!--                </template>-->
<!--            </el-table-column>-->

            <el-table-column min-width="150"  label="商品信息" show-overflow-tooltip>
                <template #default="scope">
                  <img :src="scope.row.goodsinfo.spu_default_image" alt="" style="width: 50px;height:50px;margin-right: 5px">
                  <span style="width:90px" class="ellipsis">{{scope.row.goodsinfo.sku_spec}}</span>
                  <span>{{"￥"+scope.row.price+"x"+scope.row.count}}</span>
                </template>
            </el-table-column>
            <el-table-column min-width="150"  label="评价图片" show-overflow-tooltip>
                <template #default="scope">
                  <div v-if="scope.row.images">
                    <div style="display: contents;align-items: center;margin: 5px 0" v-for="(item,index) in scope.row.images">
<!--                        <img :src="static_url + item" alt="" style="width: 50px;height:50px;margin-right: 5px">-->
                      <el-image class="imagessss"
                        style="width:60px; height:60px"
                        fit="contain"
                        :src="static_url + item"
                        :preview-src-list="scope.row.images.map(image => static_url + image)">
                      </el-image>
                    </div>

                  </div>
                  <div v-else>
                     没有上传图片
                  </div>

                </template>
            </el-table-column>
<!--            <el-table-column min-width="120"  label="是否关闭评论" show-overflow-tooltip>-->
<!--                <template #default="scope">-->
<!--                  <el-switch v-model="scope.row.is_commented" active-color="#13ce66" active-text="打开"-->
<!--                             @change="ChangeEvaluateStatus(scope.row)" inactive-text="关闭" inactive-color="#ff4949"></el-switch>-->
<!--                </template>-->
<!--            </el-table-column>-->
            <el-table-column min-width="120" prop="create_datetime" label="创建时间"></el-table-column>
            <el-table-column label="是否关闭评论" fixed="right" min-width="100">
                <template #header>
                    <div style="display: flex;justify-content: space-between;align-items: center;">
                        <div>是否关闭评论</div>
                        <div @click="setFull">
                            <el-tooltip content="全屏" placement="bottom">
                                <el-icon ><full-screen /></el-icon>
                            </el-tooltip>
                        </div>
                    </div>
                </template>
                <template #default="scope">
                  <el-switch v-model="scope.row.is_commented" active-color="#13ce66" active-text="打开"
                             @change="ChangeEvaluateStatus(scope.row)" inactive-text="关闭" inactive-color="#ff4949"></el-switch>
                </template>
<!--                <template #default="scope">-->
<!--                    <span class="table-operate-btn" @click="handleEdit(scope.row,'delete')" v-show="isShowBtn('mallOrderManage','商城订单','Delete')">删除</span>-->
<!--                </template>-->
            </el-table-column>
        </el-table>
        <Pagination v-bind:child-msg="pageparm" @callFather="callFather"></Pagination>
        <shopping-mall-order-detail ref="shoppingMallOrderDetailFlag"></shopping-mall-order-detail>
        <deliver-goods-module ref="deliverGoodsModuleFlag" @refreshData="getData"></deliver-goods-module>
    </div>
</template>
<script>
    import shoppingMallOrderDetail from "./components/shoppingMallOrderDetail";
    import deliverGoodsModule from "./components/deliverGoodsModule";
    import Pagination from "@/components/Pagination";
    import {dateFormats} from "@/utils/util";
    import {mallGoodsOrderEvaluateList, mallGoodsOrderstatistics, mallGoodsOrderEvaluateUpdate} from '@/api/api'
    import { lyMixins } from "@/mixins/mixins"
    import { static_url } from '@/api/url';
    export default {
        mixins: [lyMixins],
        components:{
            Pagination,
            shoppingMallOrderDetail,
            deliverGoodsModule
        },
        name:'mallOrderManage',
        data() {
            return {
                isFull:false,
                loadingPage:false,
                defaultImg:"this.src='"+require('../../assets/img/avatar.jpg')+"'",
                // 选项框选中数组
                ids: [],
                // 选项框非单个禁用
                single: true,
                // 非多个禁用
                multiple: true,
                formInline:{
                    name:'',
                    status:'',
                    page: 1,
                    limit: 10,
                    orderid: null
                },
                pageparm: {
                    page: 1,
                    limit: 10,
                    total: 0
                },
                static_url: static_url,
                timers:[],
                tableData:[],
            }
        },
        methods:{
            setFull(){
              console.log(233)
                this.isFull=!this.isFull
                window.dispatchEvent(new Event('resize'))
            },
            // 表格序列号
            getIndex($index) {
                // (当前页 - 1) * 当前显示数据条数 + 当前行数据的索引 + 1
                return (this.pageparm.page-1)*this.pageparm.limit + $index +1
            },
            //多选项框被选中数据
            handleSelectionChange(selection) {
                this.ids = selection.map(item => item.id);
                this.single = selection.length !== 1;
                this.multiple = !selection.length;
            },
            // 关闭评论
            ChangeEvaluateStatus(row){
              console.log(333, row)
              mallGoodsOrderEvaluateUpdate({'is_commented': row.is_commented, 'id': row.id}).then(res => {
                 if(res.code ==2000) {
                     console.log(111, res.data.data)
                 }
              })
            },
            /** 批量删除按钮操作 */
            handleDelete(row) {
                const ids = this.ids;
                let vm = this
                vm.$confirm('是否确认删除选中的数据项?', "警告", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning"
                }).then(function() {
                    return mallGoodsOrderDelete({id:ids}).then(res=>{
                        if(res.code == 2000) {
                            vm.$message.success(res.msg)
                            vm.search()
                        } else {
                            vm.$message.warning(res.msg)
                        }
                    })
                })
            },
            handleEdit(row,flag) {
                let vm = this
                if(flag=='detail') {
                    this.$refs.shoppingMallOrderDetailFlag.orderDetailFn(row)
                }
                else if(flag=='deliver') {
                    this.$refs.deliverGoodsModuleFlag.deliverGoodsModuleFn(row)
                }
                else if(flag=='delete') {
                    console.log('row', row, 'flag', flag)
                    // vm.$confirm('确定删除该订单？删除后无法恢复？',{
                    //     closeOnClickModal:false
                    // }).then(res=>{
                    //     mallGoodsOrderDelete({id:row.id}).then(res=>{
                    //         if(res.code == 2000) {
                    //             vm.$message.success(res.msg)
                    //             vm.search()
                    //         } else {
                    //             vm.$message.warning(res.msg)
                    //         }
                    //     })
                    // }).catch(()=>{
                    //
                    // })
                }
                else if(flag=="reset"){
                    this.formInline = {
                        page:1,
                        limit: 10
                    }
                    this.pageparm={
                        page: 1,
                        limit: 10,
                        total: 0
                    }
                    this.timers = []
                    this.search()
                }
            },

            callFather(parm) {
                this.formInline.page = parm.page
                this.formInline.limit = parm.limit
                this.getData()
            },
            search() {
                this.formInline.page = 1
                this.formInline.limit = 10
                this.getData()
            },
            //获取订单（评价）列表
            async getData() {
                this.loadingPage = true
                 mallGoodsOrderEvaluateList(this.formInline).then(res => {
                     this.loadingPage = false
                     if(res.code ==2000) {
                         console.log(111, res.data.data)
                         this.tableData = res.data.data
                         this.pageparm.page = res.data.page;
                         this.pageparm.limit = res.data.limit;
                         this.pageparm.total = res.data.total;
                     }
                 })
            },

            timeChange(val){
                if (val) {
                    this.formInline.beginAt=dateFormats(val[0],'yyyy-MM-dd hh:mm:ss');
                    this.formInline.endAt=dateFormats(val[1],'yyyy-MM-dd hh:mm:ss');
                } else {
                    this.formInline.beginAt = null
                    this.formInline.endAt = null
                }
                this.search()
            },
            // handleSelectionChange(e) {
            //   console.log(e,'e----')
            // },
        },
        created() {
            console.log(345)
            this.getData()
            // this.getOrderstatistics()
        },
    }
</script>
<style lang="scss">
    .order-static{
        display: flex;
        font-size: 14px;
        background: var(--l-order-statics);
        padding: 10px;
        li{
            margin-right: 30px;

        }
    }
    .el-table .el-table__cell{
      position: static;
    }
    //.imagessss img{
    //  width: 50px;
    //  height: 50px;
    //}
</style>
