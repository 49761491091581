<template>
  <el-container class="index-con">
    <el-header class="index-header lyadmin-header">
      <navcon></navcon>
    </el-header>
    <el-container class="main-con">
      <el-aside :class="showclass" class="lyadmin-side">
        <el-scrollbar>
          <leftnav ></leftnav>
        </el-scrollbar>
      </el-aside>
      <el-main class="index-main" v-if="isMultiTabs">
        <mutitabs></mutitabs>
      </el-main>
      <el-main class="index-main" v-else>
        <keep-alive>
          <router-view v-if="$route.meta.isActive"></router-view>
        </keep-alive>
        <router-view v-if="!$route.meta.isActive"></router-view>
      </el-main>
      <el-button @click="openright" type="primary" style="margin-left: 16px;">
        客服消息{{allinfomation}}
      </el-button>
        <el-drawer
          class="mydrawer"
          title="客服"
          show-close
          size="95%"
          v-model="drawer"
          :direction="direction"
          :before-close="handleClose">
            <div class="chat">
              <el-row type="flex" justify="center">
              <el-col :span="20" class="colwidth">
                  <el-container>
                      <el-aside width="240px">
                          <el-card>
                              <div>
                                  <span>客服</span>
                              </div>
                          </el-card>
                          <el-menu :default-active="String(currentSessionId)">
                              <el-menu-item v-for="session in sessions" @click="selectMenuItem(session)" :key="session.id" :index="String(session.id)">
                                {{ session.user.name }}
                                <el-badge v-if="session.user.badge > 0" class="mark" :value="session.user.badge" />
                              </el-menu-item>
                          </el-menu>
                      </el-aside>

                      <el-main id="chat">
                          <div class="message">
                              <ul v-if="currentSessionId">
                                  <li v-for="item in currentSession.messages">
                                      <p class="time">
                                          <span>{{ item.time | time }}</span>
                                      </p>
                                      <div class="main" :class="{ self: item.type == 'service' }">
        <!--                                  <img class="avatar" :src="item.type == 'service' ? user.avatar : currentSession.user.avatar" />-->
                                          <img class="avatar" src="../assets/clientchat.jpg" />
                                        <div class="text">{{ item.message }}</div>
                                      </div>
                                  </li>
                              </ul>
                          </div>
                          <div class="word">
                              <el-input type="textarea" :rows="4" @keyup="onKeyup" v-model="content" :disabled="!currentSessionId">
                              </el-input>
                          </div>
                          <div class="send">
                              <span>按 Ctrl + Enter 发送</span>
                              <el-button plain size="mini" v-on:click="sendMessage">发送</el-button>
                          </div>
                      </el-main>
                  </el-container>
              </el-col>
          </el-row>
          </div>
        </el-drawer>
    </el-container>
  </el-container>
</template>
<script setup>
    import {ref, onMounted,getCurrentInstance,computed, nextTick} from 'vue'
    import navcon from '../components/navcon.vue'
    import leftnav from '../components/leftnav.vue'
    import Mutitabs from "../components/mutitabs";
    import {useMutitabsStore} from "@/store/mutitabs";
    import {useSiteThemeStore} from "@/store/siteTheme";
    import { domain } from '@/api/url';

    let bus = getCurrentInstance().appContext.config.globalProperties.$Bus; // 声明$Bus
    let showclass = ref("asideshow")
    let showtype = ref(false)
    const mutitabsStore =  useMutitabsStore()
    let isMultiTabs = mutitabsStore.isMultiTabs

    const siteThemeStore = useSiteThemeStore()

    const asideshowWidth = computed(()=>{
        return siteThemeStore.menuWidth +'px'
    })


    const drawer = ref(false)
    const direction = ref('rtl')
    const content = ref('');
    const user = ref({
      name: 'coffce',
      avatar: '/static/images/self.jpg'
    });
    const sessions = ref([]);
    const currentSessionId = ref(null);
    let socket = null;
    const allinfomation = ref(0)

    const currentSession = computed(() => {
      return sessions.value.find(session => session.id === currentSessionId.value);
    })
    function handleClose(){
      currentSessionId.value = null
      drawer.value = false
    }
    function openright(){
      if (drawer.value){
        currentSessionId.value = ''
        console.log(999, currentSessionId)
        drawer.value = false

      }else{
        drawer.value = true
      }
      console.log(drawer.value, 88888)
    }

    function onKeyup() {
      return true;
    }
    // 发送消息
    function sendMessage(event) {
      let message = content.value;

      if (message) {
        let id = currentSession.value.id;
        let type = 'service';
        let time = new Date();
        let name = user.value.name;
        let avatar = user.value.avatar;

        currentSession.value.messages.push({
          time: time.toString(),
          type: type,
          message: message
        });

        socket.send(JSON.stringify({
          'id': id,
          'type': 'service_message',
          'time': time.toString(),
          'name': name,
          'avatar': avatar,
          'message': message
        }));
        if (currentSessionId.value) {
          nextTick(() => {
            let messageContainer = document.querySelector('div.message');
            messageContainer.scrollTop = messageContainer.querySelector('ul').scrollHeight;
          });
        }
        content.value = '';
      }
    }
    const timeFilter = (date) => {
      if (typeof date === 'string') {
        date = new Date(date);
        return date.toLocaleTimeString();
      }
      return date;
    };

    function selectMenuItem(session){
      currentSessionId.value =session.id
      console.log(typeof currentSession.value, 1000)
      console.log(currentSession,777)
      if (allinfomation.value != 0){
        allinfomation.value = allinfomation.value - currentSession.value.user.badge
      }
      currentSession.value.user.badge=0;
    }

    onMounted(()=>{
        bus.on('toggle', value => {
            //console.log('value')
            if (value) {
                showclass.value = 'asideshow'
            } else {
                setTimeout(() => {
                    showclass.value = 'aside'
              }, 300)
            }
        })

        let ws_protocol = window.location.protocol === 'https:' ? 'wss://' : 'ws://';
        let connection_string = ws_protocol + domain + '/ws/service/1/';
        console.log('in service.html, connection string:' + connection_string);
        socket = new WebSocket(connection_string);
          socket.onmessage = (e) => {
            let data = JSON.parse(e.data);
            let id = data['id'];
            let time = data['time'];
            let type = data['type'];
            let message = data['message'];
            let name = data['name'];
            let avatar = data['avatar'];

            switch (type) {
              case 'client_message':
                let session = sessions.value.find(session => session.id === id);
                if (session) {
                  session.messages.push({
                    time: time,
                    type: 'client',
                    message: message,
                  });
                  if (session.id !== currentSessionId.value) {
                    session.user.badge += 1;
                    allinfomation.value = allinfomation.value + 1
                  }

                } else {
                  sessions.value.push({
                    id: id,
                    user: {
                      name: name,
                      avatar: avatar,
                      badge: 1,
                    },
                    messages: [{
                      time: time,
                      type: 'client',
                      message: message,
                    }]
                  });
                  allinfomation.value = allinfomation.value +1


                }
                if (currentSessionId.value) {
                  nextTick(() => {
                    let messageContainer = document.querySelector('div.message');
                    messageContainer.scrollTop = messageContainer.querySelector('ul').scrollHeight;
                  });
                }
                break;
              case 'service_message':
                console.log('service_message');
                break;
            }
          };

          socket.onopen = () => {
            console.log("opening a connection...");
          };

          socket.onerror = (evt) => {
            console.log("ERR: " + evt.data);
          };

          socket.onclose = (e) => {
            console.error('Chat socket closed unexpectedly');
          };
    })


</script>
<style lang="scss" scoped>
  .main-con{
    width:100%;
    height: 100%;
    /*overflow-y: auto;*/
  }
  .hg100{
    height: 100vh !important;
    overflow-y: hidden !important;
  }
  .index-con {
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    overflow-y: hidden;
  }

  .aside {
    width: 64px !important;
    background-color: var(--l-header-bg);
    margin: 0px;
    box-shadow: 0 0 5px #cccccc;
    height: calc(100vh - 60px);
  }

  .asideshow {
    width: v-bind(asideshowWidth);
    height: calc(100vh - 60px);
    background-color: var(--l-header-bg);
    margin: 0px;
    box-shadow: 0 0 3px #cccccc;
  }
  .index-main {
    display: block;
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
    overflow: auto;
    padding: 8px 8px 0 8px;
    width: 100%;
  }
  .index-header{
    padding: 0px;
    width: 100%;
    box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.08);
  }
  .el-main.noPadding{
    padding: 0px !important;
    border-left: 2px solid #333;
  }
    .mydrawer{
      width: 100%;
      //background: #f5f5f5 url('../assets/img/bg.jpg') no-repeat center;
    }

    .chat {
        margin: 0px auto;
        overflow: hidden;
        border-radius: 3px;
        box-sizing: border-box;
        color: #4d4d4d;
        font: 14px/1.4em 'Helvetica Neue', Helvetica, 'Microsoft Yahei', Arial, sans-serif;
        background-size: cover;
        background-color: #fff;
        .colwidth {
          //background: no-repeat url("../assets/img/bg.jpg");
          border: 1px solid #1a1a1a;
        }
        .el-aside {
          color: #333;
          height: 600px;
          //background-color: #fff;

        }
        .el-main {
            padding: 0px;
            border-left: solid 1px #ddd;
        }
        .el-card {
          padding: 14px;
          .time {
              font-size: 13px;
              color: #999;
          }
          .bottom {
              margin-top: 13px;
              line-height: 12px;
          }
          .button {
              padding: 0;
              float: right;
          }
          .image {
              width: 100%;
              display: block;
          }
          .clearfix:before,
          .clearfix:after {
              display: table;
              content: "";
          }
          .clearfix:after {
              clear: both
          }
        }
    }

    #chat *:before *:after {
        box-sizing: inherit;
    }

    #chat .message {
        height: 420px;
        padding: 10px 15px;
        overflow-y: scroll;
        border: 1px solid #eee;
        background-color: #f5f5f5
    }

    #chat .message ul {
        margin: 0;
        padding: 0;
        list-style: none;
    }

    #chat .message li {
        margin-bottom: 15px;
    }

    #chat .message .time {
        margin: 7px 0;
        text-align: center;
    }

    #chat .message .time>span {
        display: inline-block;
        padding: 0 18px;
        font-size: 12px;
        color: #fff;
        border-radius: 2px;
        background-color: #dcdcdc;
    }

    #chat .message .avatar {
        float: left;
        margin: 0 10px 0 0;
        border-radius: 3px;
        height: 30px;
        width: 30px;
    }

    #chat .message .text {
        display: inline-block;
        position: relative;
        padding: 0 10px;
        max-width: calc(100% - 80px);
        min-height: 30px;
        line-height: 2.5;
        font-size: 12px;
        text-align: left;
        word-break: break-all;
        background-color: #fafafa;
        border-radius: 4px;
    }

    #chat .message .text:before {
        content: " ";
        position: absolute;
        top: 9px;
        right: 100%;
        border: 6px solid transparent;
        border-right-color: #fafafa;
    }

    #chat .message .self {
        text-align: right;
    }

    #chat .message .self .avatar {
        float: right;
        margin: 0 0 0 10px;
    }

    #chat .message .self .text {
        background-color: #b2e281;
    }

    #chat .message .self .text:before {
        right: inherit;
        left: 100%;
        border-right-color: transparent;
        border-left-color: #b2e281;
    }

    #chat .word {
        height: 90px;
        border-top: solid 1px #ddd;
        padding: 10px 10px 0 10px;
    }

    #chat .word textarea {
        padding: 0;
        height: 100%;
        width: 100%;
        border: none;
        outline: none;
        font-family: "Micrsofot Yahei";
        resize: none;
    }

    #chat .send {
        text-align: right;
        padding: 5px;
        height: 40px;
        border: none;
        outline: none;
        font-family: "Micrsofot Yahei";
        color: #888;
    }

</style>